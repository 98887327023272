<template>
  <div class="product-variant__product-sizes--mobile">
    <UDrawer v-model:open="drawerOpen" :title="$t('Select your size')">
      <UButton
        :class="{
          '[&>span[class*=chevron-up]]:rotate-180 [&>span[class*=chevron-up]]:transform [&>span[class*=chevron-up]]:transition':
            drawerOpen,
        }"
        :label="selectedVariant.uid ? selectedVariant?.label : $t('Select your size')"
        color="secondary"
        variant="outline"
        trailing-icon="i-lucide-chevron-up"
      />

      <template #body>
        <div class="bg-neutral-100 -mx-4 px-4 py-1">
          <UButton class="underline" variant="link" color="secondary" size="xs" @click="openSizeChart">
            {{ $t('Size chart') }}
          </UButton>
        </div>
        <UButton
          v-for="size in option.values"
          :key="size.uid"
          class="border-b-1 rounded-none border-neutral-200 relative"
          :class="{
            'product-size--selected': selectedVariant.uid === size.uid,
            'product-size--out-of-stock': getDisabledForOption(size.out_of_stock) || disabled,
          }"
          variant="ghost"
          color="secondary"
          :data-sku="selectedProductSku(size.uid)"
          :data-id="selectedProductId(size.uid)"
          :data-label="size.label"
          :data-attribute_code="option.attribute_code"
          @click="selectOption(size)"
        >
          {{ size.label }}
          <template v-if="type === 'cart' && size.stock_indication === 1">
            <ProductStockWarningIndicator class="ml-auto h-[6px] w-[6px]" />
            <p class="text-xs">Bijna uitverkocht</p>
          </template>
          <template v-if="getDisabledForOption(size.out_of_stock)">
            <BellIcon class="ml-auto" />
            <p class="text-xs">Houdt me op de hoogte</p>
          </template>
        </UButton>
      </template>
    </UDrawer>
  </div>
</template>

<script setup lang="ts">
import BellIcon from '~/storefront/assets/icons/bell.svg'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  selectedVariant: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['selectVariant'])

const drawerOpen = ref(false)

const { toggleIsSizeChartSidebarOpen } = useUiState()
const { isSizeChartSidebarOpen, soldOutForm } = storeToRefs(useUiState())

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id

const openSizeChart = async () => {
  toggleIsSizeChartSidebarOpen()
  drawerOpen.value = false

  watch(
    isSizeChartSidebarOpen,
    () => {
      drawerOpen.value = true
    },
    { once: true },
  )
}

const selectOption = (size: Object) => {
  emit('selectVariant', size)
  drawerOpen.value = false

  watch(
    soldOutForm,
    () => {
      drawerOpen.value = true
    },
    { once: true },
  )
}
</script>
