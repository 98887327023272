<template>
  <div class="product-variant__heading">
    <SfProperty :name="$t('Size')" :value="selectedVariant.label" />

    <div class="product-variant__size-chart-button">
      (<SfButton class="sf-button--text" @click="toggleIsSizeChartSidebarOpen"> {{ $t('Size chart') }} </SfButton>)
    </div>
  </div>
  <div class="product-variant__product-sizes--desktop">
    <SfButton
      v-for="size in option.values"
      :key="size.uid"
      class="product-size"
      :class="{
        'product-size--selected': selectedVariant.uid === size.uid,
        'product-size--out-of-stock': getDisabledForOption(size.out_of_stock) || disabled,
      }"
      :data-sku="selectedProductSku(size.uid)"
      :data-id="selectedProductId(size.uid)"
      :data-label="size.label"
      :data-attribute_code="option.attribute_code"
      @click="$emit('selectVariant', size)"
    >
      {{ size.label }}
      <ProductStockWarningIndicator
        v-if="type === 'cart' && size.stock_indication === 1"
        class="product-size__stock-indication"
      />
      <BellIcon v-if="getDisabledForOption(size.out_of_stock)" />
    </SfButton>
  </div>
</template>

<script setup lang="ts">
import BellIcon from '~/storefront/assets/icons/bell.svg'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  selectedVariant: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['selectVariant'])

const { toggleIsSizeChartSidebarOpen } = useUiState()

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id
</script>

<style lang="scss" scoped>
html.theme--storefront {
  .product-variant__product-sizes--desktop {
    display: flex;
    flex-flow: row wrap;

    .is-disabled--button {
      pointer-events: all;
    }
  }

  .product-size {
    --button-height: var(--variant-button-height);
    --button-background: var(--gray-background-color);
    --button-color: var(--black-color);
    --button-padding: 0 var(--spacer-sm);
    --button-font-size: var(--font-size--xs);
    --button-font-weight: var(--font-weight--normal);
    border: 1px solid var(--gray-background-color);
    margin: 0 var(--spacer-xs) 0 0;
    width: 50px;

    &:hover,
    &:focus,
    &--selected {
      --button-background: var(--gray-background-color);
      --button-color: var(--black-color);
      border-color: var(--black-secondary-color);
    }

    &__stock-indication {
      position: absolute;
      right: -6px;
      top: -6px;
      width: 12px;
      height: 12px;
      border: 2px solid white;
    }

    &--out-of-stock {
      border: 1px solid var(--gray-background-color);
      --button-background: var(--white-color);
      --button-color: var(--gray-secondary-color);
      --button-box-shadow: none;
      --button-hover-box-shadow: none;

      &:hover,
      &:focus {
        border: 1px solid #c3c3c3;
        --button-background: var(--white-color);
        --button-color: var(--gray-secondary-color);
        --button-box-shadow: none;
        --button-hover-box-shadow: none;
      }

      svg {
        position: absolute;
        top: -3px;
        right: -4px;
        color: var(--black-color);
      }
    }
  }

  .product-variant__size-chart-button,
  .product-variant__size-chart-button button {
    font-size: var(--font-size--xs);
  }
}
</style>
